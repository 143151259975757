<template>
  <v-combobox v-model="mentor" hide-details :items="listMentor" item-text="name" item-value="id" outlined dense />
</template>

<script>
import { defineComponent, reactive, watch, toRefs, onBeforeMount } from '@vue/composition-api'

export default defineComponent({
  props: { value: [String, Object] },

  setup(props, { root, emit }) {
    const state = reactive({
      listMentor: [],
      mentor: null,
    })

    onBeforeMount(async () => {
      state.listMentor = await root.$store.dispatch('mentor/getListMentor', {
        offset: 1000,
      })

      state.mentor = props.value
    })

    watch(
      () => props.value,
      () => {
        state.mentor = props.value
      },
    )

    watch(
      () => state.mentor,
      () => {
        emit('input', state.mentor)
      },
    )

    return {
      ...toRefs(state),
    }
  },
})
</script>
