<template>
  <v-breadcrumbs :items="items" class="pa-0" color="black">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@vue/composition-api'

export default defineComponent({
  props: { value: [String, Object] },

  setup(props, { root }) {
    const state = reactive({
      items: [
        {
          text: '대시보드',
          href: '/',
        },
        {
          text: root.$route.name,
          href: root.$route.path,
          disabled: true,
        },
      ],
    })

    return {
      ...toRefs(state),
    }
  },
})
</script>

<style>
.v-breadcrumbs__item {
  color: inherit !important;
}
</style>
